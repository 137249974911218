import { useContext, useState } from "react";
import {
  EditOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  InboxOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Flex,
  Image,
  Input,
  Modal,
  Popconfirm,
  Skeleton,
} from "antd";
import { message, Upload } from "antd";
import { stateContext } from "../stateContext";
import axios from "axios";

const { Dragger } = Upload;
const { Meta } = Card;

export default function HeaderSlides({ fetchSlides, data, loading }) {
  const { mobile, tablet, laptop, apiUrl, serverUrl } =
    useContext(stateContext);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState();
  const [uploaded, setUploaded] = useState(false);

  const props = {
    name: "file",
    multiple: false,
    accept: ".png, .jpg, .jpeg",
    action: serverUrl + "upload",
    showUploadList: { showRemoveIcon: true },
    onChange(info) {
      const { status } = info.file;
      setFile(info.file.name);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploaded(true);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped file", e.dataTransfer.files);
    },
    disabled: uploaded,
  };

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    message.loading({ content: "Storing data", duration: 0 });
    try {
      let config = {
        method: "post",
        url: apiUrl + "/addPicture",
        data: {
          title: title,
          description: description,
          file: file,
          position: "header",
        },
      };

      await axios.request(config);
      message.destroy();
      fetchSlides();
      message.success("Operation successful");

      setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
      }, 2000);
    } catch (error) {
      console.log("An error occured when adding pic");
      setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
      }, 2000);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDelete = (key) =>
    new Promise(async (resolve) => {
      setTimeout(() => resolve(null), 3000);
      message.loading({ content: "Deleting data", duration: 0 });
      try {
        let config = {
          url: apiUrl + "/deletePicture/" + key,
          method: "post",
        };
        await axios.request(config);
        message.destroy();
        fetchSlides();
        message.success("Operation successful");
      } catch (error) {
        console.log(error);
      }
    });

  const handleEdit = (key) => {
    // console.log("Edit+ " + key + " + icon clicked");
  };

  const images =
    data.data && data.data.length > 0
      ? data.data.filter((element) => element.position === "header")
      : [];

  return (
    <div className="flex flex-col gap-5">
      <Flex justify="space-between">
        <span>Images that slides on the website landing page (homePage)</span>
        {mobile ? (
          <Button icon={<SyncOutlined />} onClick={fetchSlides} />
        ) : (
          <Button icon={<SyncOutlined />} onClick={fetchSlides}>
            Reload
          </Button>
        )}
      </Flex>
      <div
        className={`grid gap-4 ${
          mobile
            ? "grid-cols-1"
            : tablet
            ? "grid-cols-2"
            : laptop
            ? "grid-cols-3"
            : "grid-cols-4"
        }  items-center`}
      >
        {images.map((card, index) => (
          <Card
            key={index}
            style={{ borderRadius: 15, overflow: "hidden" }}
            cover={<Image alt={card.position} src={serverUrl + card.imgUrl} />}
            actions={[
              <EditOutlined key="edit" onClick={() => handleEdit(index + 1)} />,
              <Popconfirm
                title="Delete an image"
                description="Are you sure about this operation? no undo!"
                onConfirm={() => handleDelete(card.id)}
                disabled={images.length === 1}
              >
                <DeleteOutlined key="delete" style={{ color: "red" }} />
              </Popconfirm>,
              <EllipsisOutlined key="ellipsis" />,
            ]}
          >
            <Skeleton loading={loading} active>
              <Meta
                title={card.title}
                description={card.description}
                style={{ lineHeight: 1 }}
              />
            </Skeleton>
          </Card>
        ))}
        <Flex
          style={{
            height: 200,
            border: 1,
            borderStyle: "solid",
            borderColor: "lightgray",
            borderRadius: 15,
            cursor: "pointer",
          }}
          justify={"center"}
          align={"center"}
          onClick={showModal}
        >
          <PlusCircleOutlined style={{ fontSize: 40, color: "gray" }} />
        </Flex>
      </div>

      <Modal
        title="Add new picture in header"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <form encType="multipart/form-data" className="flex flex-col gap-4">
          <div className="flex flex-col">
            <label htmlFor="imgTitle">Image Title</label>
            <Input
              placeholder="Image title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="imgDesc">Image Description</label>
            <Input
              placeholder="Image description"
              onChange={(e) => setDescription(e.target.value)}
              showCount
              maxLength={250}
            />
          </div>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag Image to this area to upload
            </p>
          </Dragger>
        </form>
      </Modal>
    </div>
  );
}
