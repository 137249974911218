import React, { useContext, useState } from "react";
import { Flex, Layout, theme, Typography, Avatar, Popconfirm } from "antd";
import {
  UploadOutlined,
  VideoCameraOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Footer } from "antd/es/layout/layout";
import { IoLogoOctocat } from "react-icons/io";
import { BsCamera, BsMenuApp } from "react-icons/bs";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { stateContext } from "../stateContext";
import { AuthService } from "../components";

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

export default function Main() {
  const [broken, setBroken] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { setCollapsed, profile } = useContext(stateContext);
  const navigate = useNavigate();
  const path = useLocation().pathname;

  const items = [
    { key: 1, icon: <BsMenuApp />, label: `Dashboard` },
    { key: 2, icon: <BsCamera />, label: `Pictures` },
    { key: 3, icon: <VideoCameraOutlined />, label: `Videos` },
    { key: 4, icon: <UploadOutlined />, label: `Comments` },
  ];

  async function logout() {
    AuthService.logout();
    navigate("/auth", { replace: true });
  }

  return (
    <Layout>
      <Sider
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          setBroken(broken);
        }}
        onCollapse={(collapsed, type) => {
          setCollapsed(collapsed);
        }}
        style={
          !broken && {
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }
        }
      >
        <div
          className={`demo-logo-vertical m-5 flex items-center gap-3 ${
            colorBgContainer === "light" ? "text-white" : "text-black"
          }`}
        >
          <IoLogoOctocat className="text-3xl" />
          <span className="text-lg">DanielSG</span>
        </div>
        <div className="flex flex-col gap-1 mx-1">
          {items.map((item, index) => (
            <Link
              to={item.label}
              key={index}
              className={`${
                path.includes(item.label) ? "bg-blue-50" : ""
              } px-6 py-2.5 rounded-lg flex items-center gap-2 hover:bg-gray-100`}
            >
              {item.icon} <span>{item.label}</span>
            </Link>
          ))}
        </div>
      </Sider>

      <Layout
        style={
          !broken && {
            marginLeft: 200,
          }
        }
      >
        <Header
          className="px-5 flex items-center justify-between"
          style={{
            background: colorBgContainer,
            position: "sticky",
          }}
        >
          <span className="text-lg cursor-pointer">{path.split("/")[1]}</span>
          <Flex align="center">
            <Popconfirm
              placement="bottom"
              icon={<UserOutlined />}
              title={"Welcome to Daniel/s"}
              description={"Your account is active! Keep your account secure!"}
              okText="Logout"
              cancelText="Close"
              onConfirm={logout}
            >
              <Avatar
                style={{
                  backgroundColor: "#87d068",
                }}
                icon={<UserOutlined />}
              />
              <Text>{profile?.username}</Text>
            </Popconfirm>
          </Flex>
        </Header>
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              minHeight: "80vh",
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Daniel's ©{new Date().getFullYear()} Created by N.Yoramu
        </Footer>
      </Layout>
    </Layout>
  );
}
