import React, { useContext, useEffect, useState } from "react";
import { Button, Flex, Popconfirm, Table, Tag, message } from "antd";
import { stateContext } from "../stateContext";
import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import axios from "axios";

export default function Comments() {
  const { mobile, apiUrl } = useContext(stateContext);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Subject",
      dataIndex: "subject",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      render: (priority) => (
        <Tag
          color={
            priority === "High"
              ? "red"
              : priority === "Medium"
              ? "geekblue"
              : "green"
          }
          key={priority}
        >
          {priority}
        </Tag>
      ),
    },
  ];
  async function fetchComments() {
    message.loading({ content: "Fetching data", duration: 0 });
    let config = {
      url: apiUrl + "/showComments",
      method: "get",
    };
    const res = await axios.request(config);
    setComments(
      res.data.data.map((element) => ({ ...element, key: element.id }))
    );
    message.destroy();
  }
  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleDelete = () =>
    new Promise(async (resolve) => {
      setTimeout(() => resolve(null), 10000);
      message.loading({ content: "Deleting data", duration: 0 });
      try {
        setLoading(true);
        for (const element of selectedRowKeys) {
          let config = {
            url: apiUrl + "/deleteComment/" + element,
            method: "post",
          };
          await axios.request(config);
        }
        setLoading(false);
        message.destroy();
        fetchComments();
        message.success({ content: "Operation successful" });
      } catch (error) {
        console.log(error);
        message.error({ content: "Operation failed" });
      }
    });

  return (
    <div>
      <Flex justify="space-between">
        <span className="text-lg font-semibold capitalize">
          Comments from web
        </span>
        <Flex gap={5}>
          {mobile ? (
            <Button icon={<SyncOutlined />} onClick={fetchComments} />
          ) : (
            <Button icon={<SyncOutlined />} onClick={fetchComments}>
              Reload
            </Button>
          )}
          <Popconfirm
            title="Delete comments"
            description="Are you sure about this operation? no undo!"
            onConfirm={handleDelete}
          >
            {mobile ? (
              <Button
                icon={<DeleteOutlined />}
                disabled={!hasSelected}
                loading={loading}
                danger
              />
            ) : (
              <Button
                icon={<DeleteOutlined />}
                disabled={!hasSelected}
                loading={loading}
                danger
              >
                Delete
              </Button>
            )}
          </Popconfirm>
        </Flex>
      </Flex>
      <div className="overflow-x-scroll">
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={comments}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {},
            };
          }}
        />
      </div>
    </div>
  );
}
