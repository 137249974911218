import axios from "axios";
import { authUrl } from "../stateContext";
import { message } from "antd";

const AuthService = {
  register: async (data) => {
    const config = {
      url: authUrl + `register`,
      method: "post",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    const response = await axios.request(config);
    return response.data;
  },
  login: async (data) => {
    message.loading({ content: "Authentication in progress", duration: 0 });
    const config = {
      url: authUrl + `login`,
      method: "post",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    const response = await axios.request(config);
    message.destroy();
    return response.data;
  },
  logout: async () => {
    const config = {
      url: authUrl + `logout`,
      method: "get",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    const response = await axios.request(config);
    return response.data;
  },
  getProfile: async () => {
    const config = {
      method: "get",
      url: authUrl + `getProfile`,
      withCredentials: true,
      maxBodyLength: Infinity,
    };
    const response = await axios.request(config);
    return response.data;
  },
  update: async (role, data) => {
    const config = {
      url: authUrl + `${role.toLowerCase()}.update`,
      method: "post",
      data: data,
      headers: { "Content-Type": "application/json" },
    };
    const response = await axios.request(config);
    return response.data;
  },
};

export default AuthService;
