import { Button, Flex, Input, Modal } from "antd";
import { VideoLinks } from "../components";
import { PlusOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { InboxOutlined, SyncOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import axios from "axios";
import { stateContext } from "../stateContext";

const { Dragger } = Upload;

export default function Videos() {
  const { Title } = Typography;
  const { mobile, apiUrl, serverUrl } = useContext(stateContext);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [file, setFile] = useState();
  const [uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const props = {
    name: "file",
    multiple: false,
    accept: ".png, .jpg, .jpeg",
    action: serverUrl + "upload",
    onChange(info) {
      const { status } = info.file;
      setFile(info.file.name);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploaded(true);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped file", e.dataTransfer.files);
    },
    disabled: uploaded,
  };

  const showModal = () => {
    setOpen(true);
  };

  async function fetchVideos() {
    setLoading(true);
    message.loading({ content: "Fetching data", duration: 0 });
    let config = {
      url: apiUrl + "/showVideos",
      method: "get",
    };
    const res = await axios.request(config);
    setVideos(res.data);
    setLoading(false);
    message.destroy();
  }
  useEffect(() => {
    fetchVideos();
    // eslint-disable-next-line
  }, []);

  const handleOk = async () => {
    setConfirmLoading(true);
    message.loading({ content: "Storing data", duration:0 });
    try {
      let config = {
        method: "post",
        url: apiUrl + "/addVideo",
        data: {
          title,
          description,
          file: file,
          link,
        },
      };

      await axios.request(config);
      fetchVideos();
      message.destroy();
      message.success("Operation successful");

      setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
      }, 2000);
    } catch (error) {
      console.log("An error occured when adding pic");
      setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
      }, 2000);
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Flex wrap gap="small" justify="space-between">
        <Title level={4}>Videos on Web</Title>
        <Flex gap={5}>
          {mobile ? (
            <Button icon={<SyncOutlined />} onClick={fetchVideos} />
          ) : (
            <Button icon={<SyncOutlined />} onClick={fetchVideos}>
              Reload
            </Button>
          )}
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size={"middle"}
            onClick={showModal}
          >
            Add new
          </Button>
        </Flex>
      </Flex>
      <br />
      <hr />
      <VideoLinks fetchVideos={fetchVideos} data={videos} loading={loading} />

      <Modal
        title="Add new video package"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <form encType="multipart/form-data" className="flex flex-col gap-4">
          <div className="flex flex-col">
            <label htmlFor="imgTitle">Video Title</label>
            <Input
              placeholder="Video Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="imgDesc">Video Description</label>
            <Input
              placeholder="Video description"
              onChange={(e) => setDescription(e.target.value)}
              showCount
              maxLength={250}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="vdLink">Video Description</label>
            <Input
              placeholder="Video link"
              onChange={(e) => setLink(e.target.value)}
            />
          </div>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag Image to this area to upload (thumbnail)
            </p>
          </Dragger>
        </form>
      </Modal>
    </>
  );
}
