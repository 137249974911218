import { useContext, useEffect, useState } from "react";
import "./App.css";
import { stateContext } from "./stateContext";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import {
  Authentication,
  Comments,
  Dashboard,
  Main,
  Pictures,
  Videos,
} from "./panels";

function App() {
  const { currentMode, profile } = useContext(stateContext);
  const navigate = useNavigate();
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (!profile.error && !redirected) {
      navigate("/", { replace: true });
      setRedirected(true);
    }
  }, [profile.error, redirected, navigate]);

  return (
    <div className={currentMode === "Dark" ? "dark text-white" : ""}>
      <Routes>
        {profile.error === true ? (
          <Route path="*" element={<Navigate to="/auth" replace />} />
        ) : (
          <Route path="/" element={<Main />}>
            <Route index element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/pictures" element={<Pictures />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/comments" element={<Comments />} />
          </Route>
        )}
        <Route path="/auth" element={<Authentication />} />
      </Routes>
    </div>
  );
}

export default App;
