import { Tabs, message } from "antd";
import { HeaderSlides, PagesSlides, ProfilePictures } from "../components";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { stateContext } from "../stateContext";

export default function Pictures() {
  const { apiUrl } = useContext(stateContext);
  const [pictures, setPictures] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchSlides() {
    setLoading(true);
    message.loading({ content: "Fetching data", duration: 0 });
    let config = {
      url: apiUrl + "/showPictures",
      method: "get",
    };
    const res = await axios.request(config);
    setPictures(res.data);
    setLoading(false);
    message.destroy();
  }
  useEffect(() => {
    fetchSlides();
  }, []);

  const tabItems = [
    {
      label: `Home Header`,
      key: 1,
      children: (
        <HeaderSlides
          fetchSlides={fetchSlides}
          data={pictures}
          loading={loading}
        />
      ),
    },
    {
      label: `Pages Header`,
      key: 2,
      children: (
        <PagesSlides
          fetchSlides={fetchSlides}
          data={pictures}
          loading={loading}
        />
      ),
    },
    {
      label: `Your Picture`,
      key: 3,
      children: (
        <ProfilePictures
          fetchSlides={fetchSlides}
          data={pictures}
          loading={loading}
        />
      ),
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey={1} type="card" size={"small"} items={tabItems} />
    </div>
  );
}
