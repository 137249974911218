import React, { useContext, useRef, useState } from "react";
import {
  LikeOutlined,
  StarOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  List,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  message,
} from "antd";
import { stateContext } from "../stateContext";
import axios from "axios";
import ReactPlayer from "react-player";

export default function VideoLinks({ fetchVideos, data, loading }) {
  const { mobile, tablet, apiUrl, serverUrl } = useContext(stateContext);
  const [playerVideo, setPlayerVideo] = useState({});
  const [open, setOpen] = useState(false);
  const playerRef = useRef(null);

  const showModal = (item) => {
    setOpen(true);
    setPlayerVideo(item);
  };
  const handleCancel = () => {
    setOpen(false);
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pauseVideo();
    }
  };

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  const handleDelete = (key) => {
    new Promise(async (resolve) => {
      setTimeout(() => resolve(null), 3000);
      message.loading({ content: "Deleting a video", duration: 0 });
      try {
        let config = {
          url: apiUrl + "/deleteVideo/" + key,
          method: "post",
        };
        await axios.request(config);
        message.destroy();
        fetchVideos();
        message.success("Operation successful");
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <>
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 3,
        }}
        dataSource={data.data}
        renderItem={(item) => (
          <List.Item
            key={item.title}
            actions={[
              <IconText
                icon={StarOutlined}
                text="156"
                key="list-vertical-star-o"
              />,
              <IconText
                icon={LikeOutlined}
                text="156"
                key="list-vertical-like-o"
              />,
              <Button shape="circle" size={"small"} icon={<EditOutlined />} />,
              <Popconfirm
                title="Delete this video"
                description="Are you sure about this operation? no undo!"
                onConfirm={() => handleDelete(item.id)}
                onOpenChange={() => {}}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  shape="circle"
                  size={"small"}
                />
              </Popconfirm>,
            ]}
            extra={
              <img
                width={272}
                alt="logo"
                src={serverUrl + item.thumbnail}
                onClick={() => showModal(item)}
              />
            }
          >
            <Skeleton loading={loading} active>
              <List.Item.Meta
                title={
                  <span onClick={() => showModal(item)}>{item.title}</span>
                }
                description={item.description}
              />
            </Skeleton>
          </List.Item>
        )}
      />

      <Modal
        title={"Video Player"}
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={mobile ? "100%" : tablet ? "80%" : "50%"}
        style={{ height: "auto" }}
      >
        <ReactPlayer
          ref={playerRef}
          url={playerVideo.videoUrl}
          width={"100%"}
          playing
        />
      </Modal>
    </>
  );
}
