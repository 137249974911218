import { duration, useMediaQuery } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AuthService } from "./components";
import { message } from "antd";

export const stateContext = createContext();
// export const authUrl = "http://localhost:4000/api/";
export const authUrl = "https://api.danielthesafariguide.com/api/";

export const StateProvider = ({ children }) => {
  // Responsiveness
  const smMobile = useMediaQuery("(max-width: 30px)");
  const mobile = useMediaQuery("(min-width: 30px) and (max-width: 800px)");
  const tablet = useMediaQuery("(min-width: 801px) and (max-width: 1024px)");
  const laptop = useMediaQuery("(min-width: 1025px) and (max-width: 1660px)");
  const desktop = useMediaQuery("(min-width: 1661px)");
  const smHeight = useMediaQuery("(max-height: 600px)");

  const [currentMode, setCurrentMode] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  // const apiUrl = "http://localhost:4000/api";
  // const serverUrl = "http://localhost:4000/";
  const apiUrl = "https://api.danielthesafariguide.com/api";
  const serverUrl = "https://api.danielthesafariguide.com/";

  useEffect(() => {
    const setInitialState = async () => {
      let theme = localStorage.getItem("themeMode");
      if (theme === undefined || theme === null) {
        localStorage.setItem("themeMode", "Light");
        setCurrentMode("Light");
      }
    };

    setInitialState();
  }, []);

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  useEffect(() => {
    let theme = localStorage.getItem("themeMode");
    window.localStorage.setItem("themeMode", theme);
  }, []);

  useEffect(() => {
    setCurrentMode(localStorage.getItem("themeMode"));
  }, [setCurrentMode]);

  const [showMenu, setShowMenu] = useState(false);
  const [active, setActive] = useState("/");
  const [profile, setProfile] = useState({ error: true });

  useEffect(() => {
    async function getProfile() {
      try {
        const profile = await AuthService.getProfile();
        setProfile(profile);
        message.success({ content: profile.message, duration: 5 });
      } catch (error) {
        message.error({ content: error.message, duration: 5 });
      }
    }

    getProfile();
  }, []);

  const stateValues = {
    smMobile,
    mobile,
    tablet,
    laptop,
    desktop,
    smHeight,

    currentMode,
    setMode,

    showMenu,
    setShowMenu,
    active,
    setActive,

    collapsed,
    setCollapsed,

    apiUrl,
    serverUrl,

    profile,
    setProfile,
  };

  return (
    <stateContext.Provider value={stateValues}>
      {children}
    </stateContext.Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
