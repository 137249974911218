import React, { useContext } from "react";
import { Button, Checkbox, Form, Input, Flex, message } from "antd";
import { AuthService } from "../components";
import { stateContext } from "../stateContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Authentication() {
  const { setProfile } = useContext(stateContext);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const loggedIn = await AuthService.login(values);
      if (loggedIn.error === false) {
        message.success({ content: loggedIn.message, duration: 5 });
        Cookies.set("accessToken", loggedIn.accessToken, {
          domain: ".danielthesafariguide.com",
          path: "/",
          sameSite: "Lax",
        });
        setProfile(loggedIn);
        navigate("/");
      } else {
        message.error({ content: loggedIn.message, duration: 5 });
      }
    } catch (error) {
      message.error({ content: error.message, duration: 5 });
    }
  };
  const onFinishFailed = (errorInfo) => {
    message.error("Failed:", errorInfo);
  };

  return (
    <div className="flex items-center justify-center h-[100vh] w-full">
      <div>
        <Flex gap={10} className="border-2">
          <div>Daniel the Safari Guide</div>
          <div>
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Flex>
      </div>
    </div>
  );
}
