import { Calendar, Card, Statistic, message } from "antd";
import { MessageOutlined, PictureOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { stateContext } from "../stateContext";
import axios from "axios";

export default function Dashboard() {
  const { mobile, apiUrl } = useContext(stateContext);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    message.loading({ content: "Fetching data", duration: 0 });
    let needed = [
      { url: "/showComments", key: "cm" },
      { url: "/showPictures", key: "pc" },
      { url: "/showVideos", key: "vd" },
    ];
    for (const element of needed) {
      let config = {
        url: apiUrl + element.url,
        method: "get",
      };
      const res = await axios.request(config);
      setData((d) => [...(d || []), { count: res.data.data.length }]);
    }
    message.destroy();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>Dashboard</div>

      <div
        className={`grid gap-4 ${
          mobile ? "grid-cols-1" : "grid-cols-3"
        }  items-center`}
      >
        <Card bordered className="shadow-xl shadow-slate-300">
          <Statistic
            className=""
            title="Comments"
            value={data[0]?.count}
            prefix={<MessageOutlined className="mr-2" />}
          />
        </Card>
        <Card bordered className="shadow-lg shadow-slate-300">
          <Statistic
            title="Pictures"
            value={data[1]?.count}
            prefix={<PictureOutlined className="mr-2" />}
          />
        </Card>
        <Card bordered className="shadow-xl shadow-slate-300">
          <Statistic
            className=""
            title="Videos"
            value={data[2]?.count}
            prefix={<MessageOutlined className="mr-2" />}
          />
        </Card>
      </div>

      <div className="mt-10">
        <Calendar fullscreen={false} />
      </div>
    </div>
  );
}
